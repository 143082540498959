<template>
    <div>
        <h4 v-if="props.month" class="month-header">{{ month }}</h4>
        <p v-if="bold_start !== undefined" class="text-color-primary mt-3">{{ bold_start }}</p>
        <p v-if="quote" class="mt-3"><span v-if="attribution">"</span>{{ quote }}<span v-if="attribution">"</span></p>
        <p v-if="quote_html" v-html="quote_html" class="mt-3"></p>
        <a v-if="attribution_link !== undefined" :href="attribution_link" target="_blank">
            <p class="mt-3 is-italic">- {{ attribution }}<span v-if="attribution_work !== undefined">, <u>{{ attribution_work }}</u></span>
            <span v-if="attribution_page !== undefined">, page {{ attribution_page }}</span></p>
        </a>
        <span v-else>
            <p v-if="attribution" class="mt-3 is-italic">- {{ attribution }}<span v-if="attribution_work !== undefined">, <u>{{ attribution_work }}</u></span>
            <span v-if="attribution_page !== undefined">, page {{ attribution_page }}</span></p>
        </span>

        <p v-if="attribution_extra !== undefined" class="is-italic">{{ attribution_extra }}</p>
        <p v-if="bold_end !== undefined" class="text-color-primary mt-3">{{ bold_end }}</p>
        <p v-if="html_end" v-html="html_end" class="mt-3"></p>
    </div>
</template>

<script setup>

const props = defineProps(['quoteDict', 'month'])
let quoteDict = props.quoteDict

let month = quoteDict.month
let quote = quoteDict.quote
let quote_html = quoteDict.quote_html
let attribution = quoteDict.attribution
let attribution_extra = quoteDict.attribution_extra
let attribution_work = quoteDict.attribution_work
let attribution_page = quoteDict.attribution_page
let attribution_link = quoteDict.attribution_link
let bold_start = quoteDict.bold_start
let bold_end = quoteDict.bold_end
let html_end = quoteDict.html_end

</script>

<style lang="scss" scoped>

@import '../../public/constants.scss';

.month-header {
    color: $primary;
    font-weight: 600;
}

a {
    color: $bodyText;
}

a:hover {
    color: $bodyText;
}

</style>

