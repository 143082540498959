<template>
    <div id="main-columns" class="columns is-centered mx-2 my-5">
        <div class="column is-two-thirds">
            
            <div class="header my-5">
                <h3>Our Organization</h3>
            </div>
            <p class="body-text my-5 px-2">Sunday in North Carolina is a subsidiary of Bethel Ensemble, focusing primarily on the honor and celebration of the Sabbath Day.<br><br>
                Bethel Ensemble, established 2021, is a 501(c)(3) faith-based non-profit organization whose mission is to sponsor, produce, encourage, support and provide religious, educational, and charitable activities related to the performing arts and the Biblical mandate of honoring the Sabbath.</p>
            
            <div class="divider my-2"></div>

            <h3 class="text-color-primary has-text-centered mt-5"> Sabbath {{ attribution ? "Quote" : "Thought" }} for {{quotes[0].month}}</h3>
            <div class="columns is-centered">
                <div class="column is-three-quarters has-text-centered body-text">
                    <QuoteView :quoteDict="quotes[0]" :month="false" />
                    <RouterLink to="/quotes" class="button to-quotes my-4">View Past Quotes and Thoughts</RouterLink>
                </div>
            </div>

            <div class="divider my-2"></div>

            <div class="header my-5">
                <h3>Our Background</h3>
            </div>
            <div class="body-text">
                <p>Sunday in North Carolina is a 501(c)(3) faith-based charity officially recognized by the IRS as a public charity on November 4, 2021.
                    It is a mission of Bethel Ensemble, whose E.I.N. is 86-2994560.
                    We gave ourselves 12 to 18 months to get organized. The first organizational meeting was held on April 20, 2021 at St. Paul Lutheran Church, Salisbury, NC.
                    The next organizational meeting was conducted at St. Francis Anglican Parish in Sanford, NC, on July 27, 2021.
                    At this meeting, those felt called by the Holy Spirit to establish a new group were
                    Brenda Dasher, Fr. Matthew Garrison, James S. Gibbons, Jr., Dr. Sidney 'Joe' Morgan, M.D., Brendan Mungwena, Chaplain Andrew Krey, Thomas Mitz, Gordy Reese, Cheryl Schmidt, Imelda Tablada, and Shirley Wright.
                    At that July 2021 meeting, it was decided that "Our mission is to help Tar Heels make Sunday a very special day for themselves, their family, and their community."
                    Furthermore, it was said that "you might have heard that Sunday is for faith, family, and football. We believe Sunday is for faith, family, and far-reaching benefits." </p>
                <p class="my-4 has-text-weight-bold">A little more background on Andrew Krey</p>
                <p>Chaplain Andy Krey served on the national board of the Lord's Day Alliance of the United States (LDA) established in 1888 for 12 years.
                    The mission of the LDA is to encourage all people to recognize and observe a day of Sabbath rest and to worship the risen Lord Jesus Christ on the Lord's Day, Sunday.
                    In 1969, Chaplain Krey joined the Merchant Marine and served as a crew member in the galleys on American flag supper oil tankers.
                    Since 1973, he has served as a chaplain to the maritime community and international seafarers in the ports of Boston, Houston, Providence, New Haven, New York/New Jersey, and Savannah.
                    He has visited with the crews of all types of oceangoing ships - cruise ships, container ships, chemical carriers, fishing vessels, banana boats, submarines, etc.
                    He has met with thousands of crew members, many speaking to him about how tired they were.
                    They went ten to 15 months without one eight-hour section of time off.
                    They were overworked, sleep deprived, and underpaid to be a world away from their families, for whom they were sacrificing the 'normal' life.</p>
            </div>
            <div class="header my-5">
                <h3>Events</h3>
            </div>
            <div class="columns is-centered is-vcentered is-desktop">                    
                <div class="column is-two-fifths-desktop mx-5">
                    <div class="divider my-2"></div>
                    <h3 id="watch-playback" class="text-color-primary has-text-centered p-5">Watch a playback of the Chiz Rider Concert!</h3>
                    <div class="divider my-2"></div>
                </div>
                <div class="column is-two-fifths-desktop mx-5">
                    <div class="video-container">
                        <iframe width="100%" height="auto" src="https://youtube.com/embed/5UCxVbWzY-k" allowfullscreen></iframe>
                    </div>
                </div>
            </div>

            <div class="header my-5">
                <h3>Contact Us</h3>
            </div>
            <div class="columns is-centered is-vcentered is-desktop body-text">                    
                <div class="column is-two-fifths-desktop">
                    <p class="has-text-weight-bold">Mailing Address:</p>
                    <p>P.O. Box 282<br>Olivia, NC 28368-0282</p>
                </div>
                <div class="column is-two-fifths-desktop">
                    <p class="has-text-weight-bold">Contact Executive Director (Andrew Krey):</p>
                    <p>chaplainkrey@gmail.com<br>912-441-4640 (Mobile, texts preferred)</p>
                </div>
            </div>
            <div class="columns is-centered is-vcentered is-desktop body-text">
                <div class="column is-four-fifths-desktop">
                    <a class="download" href="/sundayinnc.pdf" type="application/pdf" download="sundayinnc.pdf">Want to donate or get in touch? Click here to download a printable form!</a>
                </div>  
            </div>

            <div class="header my-5">
                <h3>Board of Directors</h3>
            </div>
            <div class="columns is-centered is-vcentered is-desktop"> 
                <div class="column is-two-fifths-desktop">
                    <ul class="body-text">
                        <li v-for="director in directors.withRoles" :key="director.name">
                            <span class="has-text-weight-bold">{{ director.name }}</span> - {{ director.role }}
                        </li>
                    </ul>
                </div>
                <div class="column is-two-fifths-desktop">
                    <ul class="body-text">
                        <li v-for="director in directors.withoutRoles" class="has-text-weight-bold" :key="director.name">
                            {{ director.name }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import quotes from "../../public/quotes.json"
import directors from "../../public/directors.json"
import QuoteView from "./QuoteView.vue"

let attribution = quotes[0].attribution

</script>


<style lang="scss">
@import '../../public/constants.scss';

.header {
    color: $secondary;
    background-color: $primary;
    padding: .75rem;
}

.divider {
    background-color: $primary;
    height: 10px;
}

#watch-playback {
    font-size: max(1.5rem, 2vw);
}

.video-container { position: relative; padding-bottom: 56.25%; padding-top: 30px; height: 0; overflow: hidden; }

.video-container iframe, .video-container object, .video-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.download {
    color: $primary;
    text-decoration: underline;
}
.download:hover {
    color: $primaryHover;
}

.to-quotes {
    background-color: $primary;
    color: $secondary;
    font-weight: 600;
    font-size: 1.2rem;
    border: none;
}

.to-quotes:hover {
    background-color: $primaryHover;
    color: $secondary;
}

</style>